import {
  Box,
  BoxProps,
  Button,
  Container,
  Flex,
  Heading,
  HeadingProps,
  SimpleGrid,
  SimpleGridProps,
} from '@chakra-ui/react';
import { graphql, Link as ReachLink } from 'gatsby';
import React from 'react';
import Headline from '../components/headline';
import Layout from '../components/layout';
import Seo from '../components/seo';
import { WpLinkType } from '../types/WpLinkType';

type Props = {
  data: {
    pageData: {
      title: string;
      template: {
        bookingConfirmation: {
          headline: string;
          text: string;
          button: WpLinkType;
        };
      };
    };
    seoData: {
      seo: {
        metaDescription: string;
        metaKeywords: string;
      };
    };
  };
};

const Confirmation = ({ data: { pageData, seoData } }: Props) => {
  const headline = pageData?.template?.bookingConfirmation?.headline || '';
  const text = pageData?.template?.bookingConfirmation?.text || '';
  const button = pageData?.template?.bookingConfirmation?.button || null;

  return (
    <Layout bgColor="#E1E3DB" invertedFooter={true}>
      <Seo {...seoData.seo} />
      <Flex flexDirection="column" flex="1" justifyContent="center">
        <Container marginY={{ base: '100px', md: '120px', lg: '140px' }}>
          <SimpleGrid {...gridProps}>
            <Heading {...headlineProps}>{headline}</Heading>
            <Box {...textProps} dangerouslySetInnerHTML={{ __html: text }} />
          </SimpleGrid>
          {button != null && (
            <Button
              as={ReachLink}
              variant="blackToOrange"
              to={button.url}
              target={button.target || '_self'}
            >
              {button.title}
            </Button>
          )}
        </Container>
      </Flex>
    </Layout>
  );
};

const gridProps: SimpleGridProps = {
  columns: { lg: 12 },
  columnGap: '40px',
};

const headlineProps: HeadingProps = {
  fontSize: { base: 'xl', md: '2xl' },
  lineHeight: 'shorter',
  marginBottom: '24px',
  gridColumn: { lg: 'span 8' },
};

const textProps: BoxProps = {
  fontSize: { base: 'md' },
  marginY: '24px',
  gridColumn: { lg: 'span 6' },
};

export default Confirmation;

export const pageQuery = graphql`
  query ConfirmationPageQuery($id: String!) {
    pageData: wpPage(id: { eq: $id }) {
      title

      template {
        ... on WpTemplate_Confirmation {
          bookingConfirmation {
            headline
            text
            button {
              title
              url
              target
            }
          }
        }
      }
    }
    seoData: wpPage(id: { eq: $id }) {
      seo {
        metaDescription
        metaKeywords
      }
    }
  }
`;
